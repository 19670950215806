import React, { useState, useCallback, useEffect, useRef } from 'react';
import Layout from '../components/layoutLiveEvent';
import SEO from '../components/seo';
import escape from 'lodash/escape';
import LiveVideoEmbed from '../embeds/LiveVideoEmbed';
import useSocket from 'use-socket.io-client';
import joypixels from 'emoji-toolkit';
import Logo from '../components/logo';

function ChatLine({ name, content }) {
  return (
    <li style={{ borderTop: '1px dotted #333', padding: '10px 0' }}>
      <strong style={{ color: 'rgb(140, 140, 140)' }}>{name}</strong>:{' '}
      <span
        dangerouslySetInnerHTML={{
          __html: joypixels.shortnameToImage(escape(content)),
        }}
      ></span>
    </li>
  );
}

const StoryThroughVoice = () => {
  const [chatName, setChatName] = useState('');
  const [chatMessage, setChatMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [chatNameInput, setChatNameInput] = useState('');
  const [socket] = useSocket('https://chat.nicolette.me', {
    autoConnect: false,
  });

  const handleSetChatName = useCallback(() => {
    setChatName(chatNameInput);
    socket.connect();
  }, [chatNameInput, setChatName]);

  const handleSetChatNameInput = useCallback(
    event => {
      setChatNameInput(event.currentTarget.value);
    },
    [setChatNameInput]
  );

  const handleSetChatMessage = useCallback(
    event => {
      setChatMessage(event.currentTarget.value.trimLeft());
    },
    [setChatMessage]
  );

  const handlePostMessage = useCallback(() => {
    if (chatMessage.length > 0) {
      socket.emit('sendChatMessage', {
        name: chatName,
        content: joypixels.toShort(chatMessage),
      });
    }
    setChatMessage('');
  }, [chatName, chatMessage]);

  const handleChatKeyPress = useCallback(
    event => {
      if (event.key === 'Enter') {
        handlePostMessage();
      }
    },
    [chatName, chatMessage]
  );

  const handleChatNameKeyPress = useCallback(
    event => {
      if (event.key === 'Enter') {
        handleSetChatName();
      }
    },
    [chatName, handleSetChatName]
  );

  const handleAddMessage = useCallback(
    message => {
      setMessages([...messages, message]);
    },
    [messages]
  );

  useEffect(() => {
    socket.on('chatMessage', handleAddMessage);
    return () => {
      socket.removeAllListeners();
    };
  }, [messages]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [messages]);

  if (chatName === '') {
    return (
      <Layout>
        <SEO
          title="Story Through Voice - Nicolette Macleod - IofC Hub Online"
          meta={[
            {
              name: 'viewport',
              content:
                'width=device-width, user-scalable=no, maximum-scale=1, initial-scale=1',
            },
          ]}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            color: '#eee',
            fontSize: '2.5em',
            fontFamily: 'serif',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        >
          <div>
            <div ref={messagesEndRef} />

            <label>
              What's your name?
              <input
                style={{
                  display: 'block',
                  margin: '20px 0',
                  background: 'transparent',
                  border: '0 none',
                  borderBottom: '1px dashed #ccc',
                  color: '#fff',
                  padding: '10px 0',
                  outline: '0 none',
                }}
                onChange={handleSetChatNameInput}
                onKeyPress={handleChatNameKeyPress}
                defaultValue={chatNameInput}
                autoFocus={true}
                type="text"
              />
            </label>
            <button
              style={{
                border: '0 none',
                background: '#eee',
                color: '#333',
                height: '1em',
                cursor: 'pointer',
              }}
              onClick={handleSetChatName}
            >
              Continue
            </button>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO
        title="Story Through Voice - Nicolette Macleod - IofC Hub Online"
        meta={[
          {
            name: 'viewport',
            content:
              'width=device-width, user-scalable=no, maximum-scale=1, initial-scale=1',
          },
        ]}
      />

      <article
        className="page-content page-content--live_event"
        style={{
          display: 'flex',
          background: 'black',
          position: 'absolute',
          width: '100%',
          height: '100%',
          flexWrap: 'nowrap',
          overflow: 'hidden',
        }}
      >
        <div
          className="live_event__video_pane"
          // style={{ flexBasis: '80%', width: '80%', alignSelf: 'center' }}
        >
          <LiveVideoEmbed
            url="https://www.youtube.com/watch?v=jK8MHicnvw0"
            isControlsEnabled={true}
            autoplay
          />
          <div style={{ padding: '10px' }}>
            {/* <Logo style={{ maxWidth: '15%', display: 'inline-block' }} /> */}
            <a
              href="https://nicolette.me/"
              target="_blank"
              className="event-button"
            >
              Visit my website
            </a>
            &nbsp;
            <a
              href="https://app.mailjet.com/widget/iframe/3lsW/EvY"
              target="_blank"
              className="event-button"
            >
              Join my mailinglist
            </a>
            &nbsp;
            <a
              href="https://nicolette.me/music/album/love-and-gold/"
              target="_blank"
              className="event-button"
            >
              Buy my album
            </a>
          </div>
        </div>
        <aside
          className="live_event__chat_pane"
          style={{
            color: '#fff',
            background: '#222',
            position: 'relative',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              maxHeight: '100%',
              position: 'absolute',
              width: '100%',
            }}
          >
            <h2
              style={{
                padding: '0 20px',
                fontSize: '1.5em',
                lineHeight: '2em',
              }}
            >
              Chat
            </h2>
            <ul
              style={{
                overflowY: 'auto',
                flexGrow: 10,
                margin: 0,
                padding: '20px',
                display: 'block',
                listStyle: 'none',
                width: '100%',
                top: '2.5em',
                bottom: '42px',
                position: 'absolute',
              }}
            >
              {messages.map(({ key, name = 'Guest', content = '...' }) => (
                <ChatLine key={key} name={name} content={content} />
              ))}
              <li ref={messagesEndRef} />
            </ul>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '14px',
                position: 'absolute',
                width: '100%',
                bottom: 0,
              }}
            >
              <input
                style={{ flexGrow: 1, padding: '10px' }}
                placeholder="Type message to chat"
                value={chatMessage}
                onChange={handleSetChatMessage}
                onKeyPress={handleChatKeyPress}
              />
              <button
                style={{
                  background: 'teal',
                  color: '#fff',
                  border: '0 none',
                  cursor: 'pointer',
                }}
                onClick={handlePostMessage}
              >
                Send
              </button>
            </div>
          </div>
        </aside>
      </article>
    </Layout>
  );
};

export default StoryThroughVoice;
